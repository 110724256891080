<template>
  <div class="container_perfil_micuentalugares">
    <p class="micuentalugares__description">{{ $t('perfil.txt_gestionar_tus_direcciones') }}</p>
    <div class="micuentalugares__address">
      <div class="list_address">
        <div
            class="item_address"
            v-for="(item,key) in listAddress"
            :key="key"
        >
          <div class="icon">
            <img
                :src="require('@/assets/icons/new/notification/cottage.svg')"
                alt="casa"
                class="icon_icon"
                v-if="item.name==='Home'"
            >
            <img
                :src="require('@/assets/icons/new/notification/work.svg')"
                alt="trabajo"
                class="icon_icon"
                v-else-if="item.name==='Work'"
            >
            <img
                :src="require('@/assets/icons/new/notification/location-on.svg')"
                alt="personalizado"
                class="icon_icon"
                v-else
            >
          </div>
          <div class="body">
            <p class="title">{{getType(item.name)}}</p>
            <p class="address">{{getFullAddress(item)}}</p>
          </div>
          <div class="icon-close">
            <svg @click="selected_address=item; showModalDeleteAddress=true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="currentColor"/>
            </svg>
          </div>
        </div>
      </div>
      <div
          class="add_address"
          @click="showModalAddAddress=true"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 13H6C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H11V6C11 5.71667 11.0958 5.47917 11.2875 5.2875C11.4792 5.09583 11.7167 5 12 5C12.2833 5 12.5208 5.09583 12.7125 5.2875C12.9042 5.47917 13 5.71667 13 6V11H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H13V18C13 18.2833 12.9042 18.5208 12.7125 18.7125C12.5208 18.9042 12.2833 19 12 19C11.7167 19 11.4792 18.9042 11.2875 18.7125C11.0958 18.5208 11 18.2833 11 18V13Z" fill="currentColor"/>
        </svg>
        <p>{{ $t('perfil.txt_anadir_una_direccion_de_entrega') }}</p>
      </div>
    </div>
  </div>
  <transition name="slide-fade">
    <ModalAddAddress
        v-if="showModalAddAddress"
        @close="showModalAddAddress=false;loadAddress()"
    ></ModalAddAddress>
  </transition>
  <transition name="slide-fade">
    <TwoButtonGenericModal
        v-if="showModalDeleteAddress"
        :title="this.$t('buttons.txt_confirmar')"
        :content="this.$t('perfil.txt_deseas_eliminar_la_direccion', { address: selected_address.address })"
        :accept="this.$t('barralateralizquierda.txt_si_salir')"
        :cancel="this.$t('perfil.txt_no_regresar')"
        @close_modal="showModalDeleteAddress=false"
        @accepted_modal="deleteAddress"
    >
    </TwoButtonGenericModal>
  </transition>
</template>

<script>
import UserServices from "@/services/user-services"
import {defineAsyncComponent} from "vue";
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal";
export default {
  components: {
    TwoButtonGenericModal,
    ModalAddAddress: defineAsyncComponent(() => import("./PerfilMiCuentaLugaresAddAddress")),
  },
  data () {
    return {
      showModalAddAddress: false,
      listAddress: [],
      showModalDeleteAddress: false,
      selected_address: [],
    }
  },
  created () {

  },
  async mounted () {

    await this.loadAddress()
  },
  methods: {
    async loadAddress () {
      try {
        let response = await UserServices.listAddress()
        if (response.data.success) {
          console.log('list', response.data.data)
          this.listAddress = response.data.data
        } else {
          //mostrar modal error
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteAddress () {
      try {
        let response = await UserServices.deleteAddress(this.selected_address.id)
        if (response.data.success) {
          this.$toast.success(this.$t('perfilmicuentalugares.txt_direccion_eliminada'))
        } else {
          this.$toast.error(response.data.message)
        }
        await this.loadAddress()
      } catch (error) {
        console.log(error)
      }
      this.showModalDeleteAddress = false
    },
    getType (value) {
      switch (value) {
        case 'Work': return this.$t('modaldelivery.txt_trabajo')
        case 'Home': return this.$t('modaldelivery.txt_casa')
        default: return value;
      }
    },
    getFullAddress(item) {
      let full_address = item.address
      if (item.district) {
        full_address = `${full_address}, ${item.district}`
      }
      if (item.reference) {
        full_address = `${full_address}. ${this.$t('dropdownaddress.txt_referencia')}: ${item.reference}`;
      }
      return full_address
    },
  },
}
</script>

<style scoped>
  .micuentalugares__address{ margin-top: 20px; }

  .add_address{
    width: 100%;
    border: 1px dashed;
    border-radius: 8px;
    padding: 10px 20px;
    color: #8F8F8F;
    text-align: center;
    cursor: pointer;
    transition: color .3s linear;
  }

  .add_address:hover{ color: var(--primary); }

  .list_address{
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
  }

  .item_address{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-shadow: 0px 0px 8px rgba(41, 41, 41, .2);
    border-radius: 8px;
    padding: 10px 20px;
    background-color: transparent;
    transition: background-color .3s linear;
  }

  .item_address:hover{ background-color: var(--complementary-100); }

  .icon img{
    display: block;
    min-width: 28px;
  }

  .title{ font-weight: var(--medium); }

  .address,
  .district{
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .icon-close{
    margin-left: auto;
    cursor: pointer;
    color: black;
    transition: color .3s linear;
  }

  .icon-close:hover{ color: var(--primary); }

  .icon-close svg{
    display: block;
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }

  @media only screen and (min-width: 1024px){
    .title{ font-size: 22px; }
    .address,
    .district{ font-size: 16px; }
    .item_address,
    .add_address{ width: 450px; }
  }
</style>