<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 40 40"
  >
    <defs>
      <linearGradient
          id="linear-gradient"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
      >
        <stop offset="0" />
        <stop
            offset="1"
            stop-color="#fff"
            stop-opacity="0"
        />
      </linearGradient>
      <clipPath id="clip-Bandera_con_1">
        <rect
            width="40"
            height="40"
        />
      </clipPath>
    </defs>
    <g
        id="Bandera_con_1"
        data-name="Bandera con #1"
        clip-path="url(#clip-Bandera_con_1)"
    >
      <g
          id="Grupo_2771"
          data-name="Grupo 2771"
          transform="translate(-330 -428.859)"
      >
        <g
            id="Grupo_2743"
            data-name="Grupo 2743"
            transform="translate(334.607 430.452)"
        >
          <path
              id="Trazado_5996"
              data-name="Trazado 5996"
              d="M239.559,121.6h-1.547V84.8a.7.7,0,0,1,.774-.605h0a.7.7,0,0,1,.773.605Z"
              transform="translate(-237.723 -84.196)"
          />
          <g
              id="Grupo_2740"
              data-name="Grupo 2740"
              transform="translate(0 3.375)"
          >
            <path
                id="Trazado_5997"
                data-name="Trazado 5997"
                d="M237.585,92.769h30.188l-6.853,8.5,6.951,8.464H237.585c-.17,0-.308-.216-.308-.482v-16C237.277,92.985,237.415,92.769,237.585,92.769Z"
                transform="translate(-237.277 -92.769)"
                fill="#e1051a"
            />
          </g>
          <path
              id="Trazado_5998"
              data-name="Trazado 5998"
              d="M237.585,92.769h30.188l-6.853,8.5,6.951,8.464H237.585c-.17,0-.308-.216-.308-.482v-16C237.277,92.985,237.415,92.769,237.585,92.769Z"
              transform="translate(-237.277 -89.394)"
              opacity="0.38"
              fill="url(#linear-gradient)"
              style="mix-blend-mode: color-burn;isolation: isolate"
          />
          <text
              id="_1"
              data-name="1"
              transform="translate(11.393 15.406)"
              fill="#fff"
              font-size="11"
              font-family="Gilroy-bold, Gilroy ☞"
              font-weight="700"
          >
            <tspan
                x="0"
                y="0"
            >{{ num }}</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BanderaCobertura",
  props: ['num']
}
</script>

<style scoped>

</style>