<template>
  <div class="modal__bg" @click="closeModal">
    <form @submit.prevent="updateUserPhone" class="modal__confirmation" @click.stop>
      <h3>{{ $('modalphoneinput.txt_ultimo_paso') }}</h3>
      <p>{{ $('modalphoneinput.txt_ingresa_tu_numero_de_celular') }}</p>
      <InputComponent
        :disabled=false
        :placeholder="$t('placeholder.txt_numero_de_celular')"
        :type="'tel'"
        :required=true
        :minlength="'9'"
        :maxlength="'9'"
        :bg_color="'var(--text-white)'"
        v-model="phoneNumber"
    >
    </InputComponent>
    <ButtonPrimary
        type="submit"
        :loading="loading"
    >
      {{txtBtnPhone()}}
    </ButtonPrimary>
    </form>
  </div>
</template>

<script>

import InputComponent from "@/components/new/inputs/InputComponent.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import UserServices from "@/services/user-services";
import {mapMutations} from "vuex";

export default {
  name: 'ModalPhoneInput',
  emits: ['closePhoneModal'],
  components: {
    ButtonPrimary,
    InputComponent,
  },
  data() {
    return {
      phoneNumber: null,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    txtBtnPhone() {
      if (this.loading) {
        return this.$t('forgotpassword.txt_cargando_dat');
      }
      return this.$t('buttons.txt_confirmar');
    },
    async updateUserPhone() {
      if (this.phoneNumber === null || this.phoneNumber.length !== 9) {
        this.$toast.error(this.$t('toast.txt_debe_ingresar_un_numero_celular'))
        return
      }
      let response2 = await UserServices.updateInfoUser({
        phone: this.phoneNumber}
      )
      if (response2.data.success) {
        this.$toast.success(this.$t('toast.txt_numero_celular_actualizado_con_exito'))
        this.setUser(response2.data.data)
        this.loading = false
        this.closeModal()
        return
      }
      this.$toast.error(response2.data.message)
      this.loading = false
    },
    closeModal() {
      this.$emit('closePhoneModal')
    }

  },
}
</script>

<style scoped>
  .modal__confirmation{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: var(--text-white);
    padding: 20px 10px;
    border-radius: 16px;
    text-align: center;
  }

  .modal__confirmation p{ font-size: 14px; }

  @media only screen and (min-width: 360px){
    .modal__confirmation{ padding: 20px; }
    .modal__confirmation p{ font-size: 16px; }
  }

  @media only screen and (min-width: 768px){
    .modal__confirmation{ padding: 30px 60px; }
  }
</style>