<template>
  <div class="modal__bg" @click="closeModal">
    <div class="wrapper__cancelBooking" @click.stop>
      <h2>{{ $t('modalcancelbooking.txt_cancelacion_de_reserva') }}</h2>
      <p>{{ $t('modalcancelbooking.txt_se_anulara_su_reserva_y_se_le') }}</p>
      <div class="cancelBooking__buttons">
        <ButtonTexto
            type="button"
            @click="closeModal"
        >
          {{ $t('dropdownaddress.txt_regresar') }}
        </ButtonTexto>
        <ButtonPrimary
            type="button"
        >
          {{ $t('modalcancelbooking.txt_cancelar_reserva') }}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineEmits } from "vue"
  import ButtonTexto from "@/components/new/buttons/ButtonTexto.vue";
  import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

  const emit = defineEmits(['close']);

  const closeModal = () => emit('close')
</script>

<style scoped>
  .wrapper__cancelBooking{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    margin-inline: auto;
    background-color: var(--text-white);
    border-radius: 16px;
    padding: 30px 15px;
  }

  h2{
    margin-bottom: 0;
    font-size: 22px;
    text-align: center;
  }

  p{
    font-size: 16px;
    text-align: center;
  }

  .cancelBooking__buttons{
    display: flex;
    gap: 20px;
  }

  @media only screen and (min-width: 768px){
    .wrapper__cancelBooking{
      gap: 30px;
      max-width: 600px;
      padding: 40px;
    }
  }
</style>