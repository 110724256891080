<template>
  <div class="encuentra_container">
    <div class="work__izquierda">
      <p class="back" @click="$router.push('/')">
        <svg class="icon-close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.82505 13L12.725 17.9C12.925 18.1 13.0209 18.3334 13.0125 18.6C13.0042 18.8667 12.9 19.1 12.7 19.3C12.5 19.4834 12.2667 19.5792 12 19.5875C11.7334 19.5959 11.5 19.5 11.3 19.3L4.70005 12.7C4.60005 12.6 4.52922 12.4917 4.48755 12.375C4.44588 12.2584 4.42505 12.1334 4.42505 12C4.42505 11.8667 4.44588 11.7417 4.48755 11.625C4.52922 11.5084 4.60005 11.4 4.70005 11.3L11.3 4.70005C11.4834 4.51672 11.7125 4.42505 11.9875 4.42505C12.2625 4.42505 12.5 4.51672 12.7 4.70005C12.9 4.90005 13 5.13755 13 5.41255C13 5.68755 12.9 5.92505 12.7 6.12505L7.82505 11H19C19.2834 11 19.5209 11.0959 19.7125 11.2875C19.9042 11.4792 20 11.7167 20 12C20 12.2834 19.9042 12.5209 19.7125 12.7125C19.5209 12.9042 19.2834 13 19 13H7.82505Z" fill="currentColor"/>
        </svg>
        {{ $t('applyjob.txt_volver_al_inicio') }}
      </p>
      <div class="work__titles">
        <h2 class="work__subtitle">{{ $t('applyjob.txt_trabaja_con_nosotros') }}</h2>
        <p>{{ $t('applyjob.txt_unete_a_nuestra_familia') }} {{getBusinessName()}}</p>
      </div>
      <form @submit.prevent="uploadCV" class="body_list">
        <MultipleSelectComponent
            @click="clickedOnSelect('jobs')"
            id='idSelectListJobsBusinessBrand'
            :bg_color="'white'"
            :optionsSelect="list_jobs"
            v-model="jobs_application_list"
            :name="'select_list_jobs'"
            :valueObj=null
            :required=true
            :placeholder="$t('placeholder.txt_puestos_de_interes')"
            :isActive="displaySelect === 'jobs'"
        >
        </MultipleSelectComponent>
        <MultipleSelectComponent
            @click="clickedOnSelect('restaurant')"
            id='idSelectRestauranteBrand'
            :bg_color="'white'"
            :optionsSelect="listLocales"
            v-model="temp_local"
            :name="'select_list_locals'"
            :valueObj="'name'"
            :required=true
            :placeholder="$t('placeholder.txt_elija_el_restaurante')"
            :isActive="displaySelect === 'restaurant'"
            @filterChanged="updateLocalList"
        >
        </MultipleSelectComponent>
        <InputComponent
          :required=true
          v-model=id_document
          :placeholder="$t('placeholder.txt_dni')"
          :maxlength="'8'"
          :minlength="'8'">
        </InputComponent>
        <InputComponent
            :required=true
            v-model=full_name
            :placeholder="$t('placeholder.txt_nombre_completo')"
            :maxlength="'70'"
            :minlength="'5'"
        >
        </InputComponent>
        <InputComponent
            :required=true
            v-model=phone
            :placeholder="$t('ordeinfocomponent.txt_celular')"
            :maxlength="'9'"
            :minlength="'9'"
        >
        </InputComponent>
        <div class="cv">
          <p class="input__class" v-if="temp_file == null">{{ $t('placeholder.txt_adjunta_tu_cv') }}</p>
          <p class="input__class" v-if="temp_file != null">{{ file_name }}</p>
          <div class="dropdown">
            <div class="upload_cv_btn">
              <input
                  class="adjunto"
                  type="file"
                  id="file"
                  placeholder="Archivo"
                  ref="file"
                  style="display: none"
                  accept="application/pdf"
                  @change="previewUploadedFile"
                  @click="temp_file=null; $refs.file.value=null"
              >
              <label for="file" v-if="temp_file == null" class="preupload default">{{ $t('applyjob.txt_adjuntar') }}</label>
              <label for="file" class="preupload default" v-if="temp_file != null">{{ $t('applyjob.txt_adjuntar') }}</label>
            </div>
          </div>
        </div>
        <p class="acotacion">{{ $t('applyjob.txt_el_tamano_maximo_de_tu_archivo') }}</p>
        <CheckboxComponent
            v-model="terms_checked"
            :required=true
            :id="'cb_comp_apply_job'"
        >
          {{ $t('applyjob.txt_he_leido_y_acepto_los') }}
        </CheckboxComponent>
        <ButtonPrimary
            type="submit"
            :loading="isApplyingJob"
            :disabled="isApplyingJob">
          {{mensaje_aplicar_trabajo}}
        </ButtonPrimary>
      </form>
    </div>
    <div class="work__derecha">
      <img src="https://quickeat.s3.us-west-2.amazonaws.com/local/Man+Man+Chi/new_web/work-background.webp" alt="Trabajo">
    </div>
  </div>
</template>
<script>
import LocalServices from "@/services/local-services"
import Utils from "@/services/util-services"
import InputComponent from "@/components/new/inputs/InputComponent";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import CheckboxComponent from "@/components/new/inputs/CheckboxComponent";
import MultipleSelectComponent from "@/components/new/inputs/MultipleSelectComponent";
import { useHead } from "@unhead/vue";
import { useRoute } from 'vue-router';

export default {
  name: 'ApplyJob',
  components: {
    MultipleSelectComponent,
    CheckboxComponent,
    ButtonPrimary,
    InputComponent,
},
  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: "¡Trabaja con nosotros!",
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Únete a la familia ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  async  mounted () {
    window.scrollTo(0,0)
    await this.listLocalesCercanos()
    this.list_jobs.sort()


  },
  data () {
    return {
      cv: '',
      listLocales: [],
      list_jobs: [
        this.$t('applyjob.txt_administrativo'),
        this.$t('applyjob.txt_azafatas_mozos'),
        this.$t('applyjob.txt_cocina'),
        this.$t('applyjob.txt_limpieza_y_mantenimiento'),
        this.$t('applyjob.txt_call_center')
      ],
      jobs_application_list: [],
      locals: [],
      terms_checked: false,
      id_document: '',
      phone: '',
      full_name: '',
      temp_file: null,
      file: null,
      file_name: '',
      isApplyingJob: false,
      temp_local: [],
      displaySelect: null,
    }
  },
  methods: {
    getBusinessName() {
      return process.env.VUE_APP_BUSINESS_COMERCIAL_NAME
    },
    clickedOnSelect(name) {
      if (this.displaySelect === name) {
        this.displaySelect = null
        return
      }
      this.displaySelect = name
    },

    updateLocalList() {
      console.log("selecciona", this.temp_local)
      this.locals = []
      for (let i=0; i< this.temp_local.length; i++) {
        this.locals.push(this.temp_local[i].id)
      }
      console.log("finalmente es", this.locals)
    },
    async previewUploadedFile (event) {
      console.log(event.target.files)
      this.temp_file = event.target.files[0]
      if (this.temp_file != null) {
        await this.viewImage()
      }
    },

    async viewImage() {
      const file = this.temp_file;
      console.log(file);
      this.file_name = file.name
      console.log("el type", file.type)
      // var file2 = new File([res], this.login.name + ' ' + this.login.subject);
    },
    async uploadCV() {
      if (this.temp_file == null) {
        this.$toast.error(this.$t('applyjob.txt_por_favor_debe'))
        return
      }
      if (this.isApplyingJob) {
        this.$toast.error(this.$t('applyjob.txt_por_favor_espere_que_termine'))
        return
      }
      if (this.jobs_application_list.length === 0) {
        this.$toast.error(this.$t('applyjob.txt_debe_seleccionar_al_menos_trabajo'))
        return
      }
      if (this.locals.length===0) {
        this.$toast.error(this.$t('applyjob.txt_debe_seleccionar_al_menos_gustaria'))
        return
      }
      if (this.id_document.length !== 8) {
        this.$toast.error(this.$t('applyjob.txt_debe_ingresar_un_numero_de_dni'))
        return
      }
      if (this.phone.length !== 9) {
        this.$toast.error(this.$t('applyjob.txt_debe_ingresar_un_numero_de_phone'))
        return
      }
      if (this.full_name.length < 6) {
        this.$toast.error(this.$t('applyjob.txt_debe_ingresar_su_nombre_completo'))
        return
      }
      if (!this.terms_checked) {
        this.$toast.error(this.$t('applyjob.txt_debe_aceptar_los_terminos_y_condiciones'))
        return
      }

      let temp_form = new FormData()
      temp_form.append('curriculum', this.temp_file)
      temp_form.append('locals', this.locals.toString())
      temp_form.append('jobs_application_list', this.jobs_application_list.toString())
      temp_form.append('id_document', this.id_document)
      temp_form.append('full_name', this.full_name)
      temp_form.append('phone', this.phone)
      this.isApplyingJob = true

      let response = await Utils.applyJobs(temp_form)

      if (response.data.success) {
        this.$toast.success(this.$t('applyjob.txt_recibimos_tus_datos'))
        this.isApplyingJob = false
      } else {
        this.$toast.error(this.$t('applyjob.txt_hubo_un_error_por_favor'))
        this.isApplyingJob = false
      }


    },

    async listLocalesCercanos () {
      let response = await LocalServices.listLocalesTodos()
      if (response.data.success) {
        this.listLocales = response.data.data.filter(local => { if (!local.deleted) { return local } })
        this.listLocales.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0
        })
      }
    },
  },
  computed: {
    mensaje_aplicar_trabajo () {
      if (this.isApplyingJob) {
        return this.$t('forgotpassword.txt_cargando_dat')
      } else {
        return this.$t('forgotpassword.txt_enviar')
      }
    }
  },
}
</script>

<style scoped>

  .work__izquierda{ padding: 25px 16px; }

  .back{
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-right: auto;
    margin-bottom: 20px;
    color: var(--text-black);
    font-size: 14px;
    font-weight: var(--semibold);
    cursor: pointer;
    text-decoration: none;
    transition: .3s linear;
  }

  .back:hover{
    text-decoration: underline;
    color: var(--primary);
    scale: 1.1;
  }

  .icon-close{
    cursor: pointer;
    transition: scale .3s linear;
  }

  .work__titles{ text-align: center; }

  .work__titles p{
    margin-top: 10px;
    font-size: 16px;
    color: black;
  }

  .work__subtitle{ font-family: var(--headings-font-family); }

  .body_list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  .cv{ display: flex; }

  .preupload{
    position: relative;
    border: 1px solid var(--complementary);
    border-radius: 0 8px 8px 0;
    color: var(--text-white);
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    font-size: var(--fs-base);
    font-weight: var(--medium);
    text-align: center;
    cursor: pointer;
    letter-spacing: 1px;
    background-color: var(--complementary);
    height: 40px;
    transition: all .3s ease;
  }

  .preupload::after{
    content: '';
    position: absolute;
    top: -1px;
    left: -5px;
    display: inline-block;
    width: 5px;
    height: 40px;
    background-color: var(--complementary);
    z-index: 5;
    transition: 0.3s ease all;
  }

  .preupload:hover{
    background-color: var(--complementary-800);
    border: 1px solid var(--complementary-800);
  }

  .preupload:hover::after{ background-color: var(--complementary-800); }

  .preupload:active{
    background-color: var(--complementary-700);
    border: 1px solid var(--complementary-700);
  }

  .preupload:active::after{ background-color: var(--complementary-700); }

  .acotacion{
    font-size: 14px;
    color: black;
  }

  .work__derecha{
    padding: 0px 16px;
  }

  .work__derecha img{ border-radius: 8px; }

  @media only screen and (min-width: 768px){
    .encuentra_container{
      display: flex;
      max-width: 1820px;
      margin-left: auto;
      margin-right: auto;
    }
    .work__izquierda{
      width: 50%;
      padding: 50px 16px;
    }
    .work__derecha{
      display: block;
      width: 50%;
    }
    .work__derecha img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;
    }
  }

  @media only screen and (min-width: 1024px){
    .encuentra_container{ gap: 40px; }
    .work__izquierda{ padding: 100px 32px; }
    .work__subtitle{ font-size: 36px; }
    .back{ font-size: 16px; }
  }

  @media only screen and (min-width: 1440px){
    .encuentra_container{ gap: 60px; }
    .work__izquierda{ width: 40%; }
    .work__derecha{
      width: 60%;
      height: 832px;
    }
  }
</style>